import { forwardRef } from 'react'
import { Tooltip as ChakraTooltip, useColorMode } from '@chakra-ui/react'

// https://chakra-ui.com/docs/overlay/tooltip

export const Tooltip = forwardRef(({ ...props }, ref) => {
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  return isDark ? (
    <ChakraTooltip ref={ref} bg="custom.black" color="white" fontSize="11px" {...props}>
      {props.children}
    </ChakraTooltip>
  ) : (
    <ChakraTooltip ref={ref} bg="secondary.base" color="white" fontSize="11px" {...props}>
      {props.children}
    </ChakraTooltip>
  )
})
