import { forwardRef } from 'react'
import { SkeletonText as ChakraSkeletonText } from '@chakra-ui/react'

// https://chakra-ui.com/docs/feedback/skeleton

export const SkeletonText = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraSkeletonText ref={ref} {...props}>
      {props.children}
    </ChakraSkeletonText>
  )
})
