import { forwardRef } from 'react'
import { Skeleton as ChakraSkeleton } from '@chakra-ui/react'

// https://chakra-ui.com/docs/feedback/skeleton

export const Skeleton = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraSkeleton ref={ref} {...props}>
      {props.children}
    </ChakraSkeleton>
  )
})
