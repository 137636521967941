import { useState } from 'react'
import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import theme from '../constants/theme'
import { Button } from './chakra/Button'
import { useShowAccented } from '../dataHooks'

const styles = {
  container: baseColor => css`
    position: relative;
    background-color: ${baseColor};
    overflow: hidden;
    cursor: pointer;
    border-radius: 9999px;
    transition: all 120ms ease 0s;
    display: flex;
    justify-content: center;
    align-items: center;

    &.focus {
      box-shadow: ${theme.shadows.outline};
      transition: box-shadow ${theme.transition.duration.normalSlow};
    }
  `,
  emoji: css`
    font-size: 23px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 23px;
    width: 24px;
  `,
  inset: baseColor => css`
    position: relative;
    border-radius: 9999px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: ${baseColor};
    overflow: hidden;
    display: inline-flex;
    flex-shrink: 0;
    -webkit-box-pack: start;
    justify-content: flex-start;
    box-sizing: content-box;
    cursor: pointer;
    padding: 3px;
    transition: all 120ms ease 0s;
  `,
  content: baseColor => css`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 500ms ease 0s;
    border-radius: 99px;
    transform: translateX(0px);
    width: 1.5rem;
    height: 1.5rem;
    overflow: hidden;

    &.moonSun {
      &.app-dark-mode-active {
        transform: translateX(1.375rem);
      }
    }

    &.effect {
      background-color: ${baseColor};
      transform: translateX(-3.1rem);
      &.app-dark-mode-active {
        transform: translateX(-0.78rem);
      }
    }
  `,
}

function DarkModeToggle({ className: classNameProps = '', css: cssProps = '', onClick: onClickProps, ...props }) {
  const [onFocus, setFocus] = useState(false)
  const { colorMode, toggleColorMode } = useColorMode()
  const darkModeActive = colorMode === 'dark'
  const className = `${classNameProps} ${cssProps} ${onFocus ? 'focus' : ''}`
  const baseColor = useShowAccented({ accented: theme.colors.accented.base, normal: theme.colors.primary.base })

  return (
    <Button
      reset
      className={className}
      onClick={e => {
        toggleColorMode()
        if (onClickProps) onClickProps(e)
      }}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      css={styles.container(baseColor)}
      {...props}
    >
      <div css={styles.inset(baseColor)}>
        <div css={styles.content(baseColor)} className={darkModeActive ? 'app-dark-mode-active moonSun' : 'moonSun'}>
          <span css={styles.emoji}>🌝</span>
        </div>
        <div css={styles.content(baseColor)} className={darkModeActive ? 'app-dark-mode-active effect' : 'effect'} />
      </div>
    </Button>
  )
}

export default DarkModeToggle
